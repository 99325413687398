class Helpers {
	displayElement(element) {
		if(element.classList.contains('d-none')) {
			element.classList.remove('d-none');
		}
		return this;
	}

	hideElement(element) {
		element.classList.add('d-none');
		return this;
	}
}
export default new Helpers();
