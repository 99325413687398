import Helpers from "./helpers";
class Search {
	#searchForm;
	#searchButton;
	constructor() {
		this.#searchForm = document.querySelector('.header__search_form');
		this.#searchButton = document.querySelector('.header__button_search');
		this.#searchButton.addEventListener('click', (event) => {
			if(this.#searchForm.classList.contains('d-none')) {
				Helpers.displayElement(this.#searchForm);
			}
			else {
				Helpers.hideElement(this.#searchForm);
			}
		});
	}
}

export default new Search();
